
import { mapState } from "vuex";
export default {
  data() {
    return {
      admins: [
        ["Management", "mdi-account-multiple-outline"],
        ["Settings", "mdi-cog-outline"],
      ],
      cruds: [
        ["Create", "mdi-plus-outline"],
        ["Read", "mdi-file-outline"],
        ["Update", "mdi-update"],
        ["Delete", "mdi-delete"],
      ],
      drawer: true,
      toggleDrawerData: false,
      sideBarMenu: [
        {
          title: "$vuetify.pages.home",
          icon: "mdi-home",
          link: "/dashboard",
          second_permission: true,
        },
        {
          title: "إدارة التصنيفات",
          icon: "mdi-apps-box",
          link: "/categories",
          permission: 3,
        },
        {
          title: "إدارة المؤشرات",
          icon: "mdi-apps-box",
          link: "/indicators",
          permission: 3,
        },
        {
          title: "إدارة القطاعات",
          icon: "mdi-domain",
          link: "/sectors",
          permission: 7,
        },
        {
          title: "$vuetify.pages.CompanyManagement",
          icon: "mdi-office-building",
          link: "#",
          children: [
            {
              title: "$vuetify.pages.companies",
              link: "/companies-management/companies",
              permission: 11,
            },
            {
              title: "$vuetify.pages.companiesSummary",
              link: "/companies-management/marketInfo-sum",
              permission: 15,
            },
            {
              title: "$vuetify.pages.mainMarket",
              link: "/companies-management/mainMarket-prices",
              permission: 19,
            },
            {
              title: "$vuetify.pages.levels",
              link: "/companies-management/indicators",
              permission: 23,
            },
            {
              title: "$vuetify.pages.companiesIndicators",
              link: "/companies-management/companies-indicators",
              permission: 23,
            },
            {
              title: "$vuetify.pages.researchFirms",
              link: "/companies-management/research-firms",
              permission: 23,
            }
          ],
        },

        {
          title: "$vuetify.pages.usersManagement",
          icon: "mdi-account-group",
          link: "/",
          children: [
            {
              title: "$vuetify.pages.users",
              link: "/users",
              permission: 31,
            },
          ],
        },
        {
          title: "$vuetify.pages.subscriptions",
          link: "#",
          icon: "mdi-shield-account-outline",
          children: [
            {
              title: "الباقات",
              link: "/subscriptions/plans",
              permission: 35,
            },
            {
              title: "اشتراكات المستخدمين",
              link: "/subscriptions/users",
              permission: 31,
            },
            {
              title: "سجل المعاملات",
              link: "/subscriptions/payments",
              permission: 31,
            },
          ],
        },
        {
          title: "إدارة الاخبار و المقالات",
          link: "/news",
          icon: "mdi-newspaper-variant-multiple",
          permission: 39,
        },
        {
          title: "إدارة التسويق",
          link: "#",
          icon: "mdi-ticket-percent",
          children: [
            {
              title: "الكوبونات",
              link: "/marketing/coupons",
              permission: 43,
            },
          ],
        },
        {
          title: "ادارة الصفحات",
          link: "/pages",
          icon: "mdi-book-open-page-variant-outline",
          permission: 47,
        },
        {
          title: "ادارة الصفحة الرئيسية",
          icon: "mdi-monitor-cellphone",
          link: "/",
          children: [
            {
              title: "تحكم في ظهور و اخفاء العناصر",
              link: "/home",
              permission: 54,
            },
            {
              title: "البانر العلوي",
              link: "/home/hero",
              permission: 53,
            },
            {
              title: "لماذا نحن",
              link: "/home/why-us",
              permission: 53,
            },
            {
              title: "مميزات محلل",
              link: "/home/features",
              permission: 53,
            },
            {
              title: "اراء العملاء",
              link: "/home/testimonials",
              permission: 53,
            },
          ],
        },
        {
          title: "إدارة اراء العملاء",
          icon: "mdi-text-box-check",
          link: "/reviews",
          permission: 87,
        },
        {
          title: "ادارة الاشعارات ",
          icon: "mdi-bell-cog-outline",
          link: "/",
          children: [
            {
              title: "ارسال  بريد الكتروني للعملاء",
              link: "/notifications/emails",
              permission: 55,
            },
            {
              title: "التحكم في اشعارات التطبيقات",
              link: "/notifications/apps-notifications",
              permission: 56,
            },
          ],
        },
        {
          title: "ادارة التقويم ",
          icon: "mdi-calendar-multiselect",
          link: "/",
          children: [
            {
              title: "التوزيعات النقدية",
              link: "/calendar/stock-divinant",
              permission: 73,
            },
            {
              title: "الاكتتابات",
              link: "/calendar/subscriptions",
              permission: 81,
            },
          ],
        },
        {
          title: "ادارة مستخدمي النظام ",
          icon: "mdi-shield-check",
          link: "/",
          children: [
            {
              title: "الصلاحيات",
              link: "/admins/permissions",
              permission: 57,
            },
            {
              title: "المستخدمين",
              link: "/admins/users",
              permission: 61,
            },
          ],
        },
        {
          title: "سجل النشاطات",
          icon: "mdi-lock-clock",
          link: "/system-log",
          permission: 65,
        },
        {
          title: "إدارة التقارير",
          icon: "mdi-text-box-check",
          link: "/reports",
          permission: 85,
        },
        {
          title: "اعدادات الموقع",
          icon: "mdi-cog-outline",
          link: "/settings",
          permission: 71,
        },
      ],
    };
  },
  computed: {
    ...mapState("general", ["toggleDrawer"]),
  },
  methods: {
    changeToggleDrawer() {
      this.$store.dispatch("general/ToggleDrawerHandler");
    },
    isPermitted(item) {
      let permitted = false;
      if (item.permission) {
        if (this.$auth.$state.user.permissions.includes(item.permission)) {
          permitted = true;
        }
      }
      return permitted;
    },
    isHomePermitted(item) {
      let permitted = false;
      if (item.second_permission) {
        if (this.$auth.$state.user.second_permission == 1) {
          permitted = true;
        }
      }
      return permitted;
    },
  },
  created() {
    // this.toggleDrawerData = this.toggleDrawer;
  },
};

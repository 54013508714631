
import { mapState } from "vuex";
export default {
  data() {
    return {
      admins: [
        ["Management", "mdi-account-multiple-outline"],
        ["Settings", "mdi-cog-outline"],
      ],
      cruds: [
        ["Create", "mdi-plus-outline"],
        ["Read", "mdi-file-outline"],
        ["Update", "mdi-update"],
        ["Delete", "mdi-delete"],
      ],
      drawer: true,
      toggleDrawerData: false,
    };
  },
  computed: {
    ...mapState("general", ["toggleDrawer"]),
  },
  methods: {
    changeToggleDrawer() {
      this.$store.dispatch("general/ToggleDrawerHandler");
    },
    async logOut() {
      await this.$auth.logout();
      await this.$toast.success("Successful Logout");
      await this.$router.push(this.localePath("/"));
    },
  },
  created() {
    // this.toggleDrawerData = this.toggleDrawer;
  },
};


import { mapState } from "vuex";
export default {
  data() {
    return {
      menu: [
        // { title: "$vuetify.pages.profile", to: "Profile" },
        // { title: "$vuetify.pages.changPassword", to: "Password" },
        // { title: "$vuetify.pages.manageSub", to: "Subscriptions" },
        // { title: "$vuetify.pages.settings", to: "Settings" },
      ],
      menuNotifications: [
        { title: "Click Me" },
        { title: "Click Me" },
        { title: "Click Me" },
        { title: "Click Me 2" },
      ],
      searchData: {
        keyword: null,
      },
      activator: "",
      dialog: false,
    };
  },
  computed: {
    ...mapState("general", ["breadCrumb", "toggleDrawer"]),
    userName() {
      return this.$auth.$state.loggedIn ? this.$auth.$state.user.name : "user";
    },
    userAvatar() {
      return this.$auth.$state.loggedIn ? this.$auth.$state.user.avatar : null;
    },
  },

  methods: {
    submitSearch: () => {
      console.log("search");
    },
    removeFilter() {
      this.searchData.keyword = null;
      this.$emit("RemoveFilters");
    },
    async logOut() {
      await this.$auth.logout();
      await this.$toast.success("Successful Logout");
      await this.$router.push("/");
    },
    changeToggleDrawer() {
      this.$store.dispatch("general/ToggleDrawerHandler");
    },
    toggleDialog() {
      this.dialog = !this.dialog;
    },
  },
};


export default {
  props: {
    name: {
      required: true,
      type: String,
    },
    width: {
      required: false,
      type: String,
    },
    stroke:{
      required: false,
      type: String,
    }
  },
  computed: {
    btnStyles() {
      return {
        width: `${this.width}px`,
      };
    },
  },
};
